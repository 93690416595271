import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';
import moment from 'moment';
import { Skeleton, Badge, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedOrganization } from 'redux/reducers/globals/globalActions';
import building from 'assets/images/building.jpg';
import { setLoginStatus } from 'redux/reducers/login/loginActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { applicationLogout } from 'services/LoginServices';
import { API_STATUS } from 'constants/app-constants';
import AddModal from 'components/add-modal';
import AddOrganization from './add-organization';

const OrganizationList = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [visible, toggleVisible] = useState(false);

	useEffect(() => {
		if (!loginRedux.isLogged) {
			// return <Redirect to={'/login'} />;
			navigate('/login');
		}
		if (loginRedux.isLogged && !loginRedux.isProfileUpdated) {
			navigate('/complete-your-profile');
		}
	}, [loginRedux.isLogged, loginRedux.isProfileUpdated, navigate]);

	useEffect(() => {
		dispatch(getApi('GET_ALL_ORGANIZATION'));
	}, [dispatch]);

	const refreshList = () => {
		dispatch(getApi('GET_ALL_ORGANIZATION'));
		toggleVisible(false);
	};

	const selectOrganization = (org) => {
		dispatch(setLoginStatus(true));
		dispatch(setSelectedOrganization(org));
		navigate('/');
	};

	let organizationDetails = useSelector((state) => state?.globalRedux?.organizations || []);
	organizationDetails = organizationDetails.sort((a, b) => b.defaultOrg - a.defaultOrg);

	return (
		<Container>
			<Row>
				<Col lg={9} md={12} xl={9} style={{ marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', padding: '10px 0' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<h6 style={{ margin: 0 }}>
							Hi {loginRedux.firstName} {loginRedux.lastName},
						</h6>
						<div>
							<Button size="sm" type="primary" style={{ marginRight: 10 }} onClick={() => toggleVisible(true)}>
								Create Organization
							</Button>
							<Button
								size="sm"
								type="primary"
								onClick={() => {
									applicationLogout();
								}}>
								Logout
							</Button>
						</div>
					</div>
					<div style={{ borderBottom: '1px solid #21b478', marginTop: '5px' }}></div>
				</Col>
			</Row>

			{/* <Row style={{ marginTop: '10px' }}>
				<Col lg={9} md={12} xl={9} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Button size="sm" style={{ padding: '2px 5px', marginTop: '5px', fontSize: '12px' }}>
							Create Organization
						</Button>
					</div>
				</Col>
			</Row> */}

			<Row>
				<Col lg={9} md={12} xl={9} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
					{!!!organizationDetails.length && globalRedux.apiStatus.GET_ALL_ORGANIZATION === API_STATUS.PENDING && <Skeleton active />}
					{!!!organizationDetails.length && globalRedux.apiStatus.GET_ALL_ORGANIZATION === API_STATUS.SUCCESS && (
						<div style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No organizations</div>
					)}

					<Row>
						{organizationDetails?.map((details, index) => {
							return !!details.default ? (
								<Col xl={4} md={4} key={index}>
									<Badge.Ribbon key={index} text="Default" placement="start">
										<Card
											className="box_shadow"
											style={{
												padding: '20px',
												marginTop: '10px',
												marginBottom: '10px',
											}}>
											<Row>
												<Col
													style={{
														height: '150px',
														display: 'grid',
														justifyContent: 'center',
														alignItems: 'center',
													}}
													xl={8}
													md={8}>
													<div
														style={{
															backgroundColor: '#f1f2f6',
															padding: '10px',
														}}>
														<img
															src={building}
															height="100px"
															width="100px"
															style={{ filter: 'opacity(0.2)', margin: '0 auto' }}
															alt="org-building"
														/>
													</div>
												</Col>
												<Col xl={16} md={16} style={{ padding: '10px' }}>
													<h5 style={{ color: '#21b478' }}>{details?.orgName}</h5>

													<br />
													<span style={{ fontSize: '11px' }}>Organization Id: {details.id}</span>
													<br />
													<span style={{ fontSize: '11px' }}>GST : {details.edition}</span>
													<br />
													{!!details.createdAt ? (
														<span style={{ fontSize: '11px' }}>
															Created On: {moment(details.createdAt).format('DD/MM/YYYY')}
														</span>
													) : null}
													{!!details.userRole ? (
														<span>
															You are an <b>{details.userRole}</b> in this organization
														</span>
													) : null}
													<br />
												</Col>
												<Col lg={2}>
													<div style={{ display: 'flex' }}>
														<Button
															onClick={() => selectOrganization(details)}
															size="sm"
															style={{ padding: '2px 5px', marginTop: '5px', fontSize: '12px' }}>
															Go To Organization
														</Button>
														<Button
															variant="outline-secondary"
															size="sm"
															style={{ padding: '2px 5px', marginLeft: '15px', marginTop: '5px', fontSize: '12px' }}>
															<BsThreeDots />
														</Button>
													</div>
												</Col>
											</Row>
										</Card>
									</Badge.Ribbon>
								</Col>
							) : (
								<Col xl={24} md={24} key={index}>
									<Badge.Ribbon style={{ display: details?.defaultOrg ? '' : 'none' }} key={index} text="Default" placement="start">
										<Card
											onClick={() => selectOrganization(details)}
											key={index}
											className="box_shadow"
											style={{
												marginTop: '10px',
												marginBottom: '10px',
											}}>
											<Row style={{ alignItems: 'center', padding: 10 }}>
												<Col
													style={{
														height: '150px',
														display: 'grid',
														justifyContent: 'center',
														alignItems: 'center',
													}}
													lg={3}
													xl={3}
													md={3}>
													<div style={{ backgroundColor: '#f1f2f6', padding: '10px', display: 'flex' }}>
														<img
															src={building}
															style={{ height: '100%', width: '70%', filter: 'opacity(0.2)', margin: '0 auto' }}
															// style={{ filter: 'opacity(0.2)', margin: '0 auto' }}
															alt="org-building"
														/>
													</div>
												</Col>
												<Col lg={9} xl={9} md={9} style={{ padding: '10px' }}>
													<h5 style={{ color: '#21b478' }}>{details?.orgName}</h5>
													<span style={{ fontSize: '11px' }}>Organization Id : {details?.orgNumber}</span>
													<br />
													<span style={{ fontSize: '11px' }}>GST : {details.gstin}</span>
													<br />
													{!!details.createdAt
														? [
																<span style={{ fontSize: '11px' }}>
																	Created On : {moment(details.createdAt).format('DD/MM/YYYY')}
																</span>,
																<br />,
														  ]
														: null}
													{!!details.userRole
														? [
																<span>
																	You are an <b>{details.userRole}</b> in this organization
																</span>,
																<br />,
														  ]
														: null}
												</Col>
												{/* <Col lg={12} style={{ padding: 20, paddingBottom: 10 }}>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<Button
															onClick={() => selectOrganization(details)}
															size="sm"
															style={{ padding: '2px 5px', marginTop: '5px', fontSize: '12px', width: '100%' }}>
															Go To Organization
														</Button>
													</div>
												</Col> */}
											</Row>
										</Card>
									</Badge.Ribbon>
								</Col>
							);
						})}
					</Row>
				</Col>
			</Row>
			<AddModal {...{ visible, toggleVisible, modalTitle: 'Create Organization', width: '25%' }}>
				<AddOrganization {...{ refreshList, closeModal: () => toggleVisible(false) }} />
			</AddModal>
		</Container>
	);
};

export default OrganizationList;
