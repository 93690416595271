import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import moment from 'moment';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { putApi } from 'redux/sagas/putApiSaga';
import AddVoucherPresentational from './add-voucher-presentational';

const AddVoucherFunctional = ({ state, setState, refreshList }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const { classes = [], vouchers: voucherHeads = [], accountBooks } = globalRedux;
	const dispatch = useDispatch();

	useEffect(() => {
		if (state?.selectedRow) {
			form.setFieldsValue({
				...state?.selectedRow,
				accbookId: state?.selectedRow?.accbookId?._id,
				voucherheadId: state?.selectedRow?.voucherheadId?._id,
				voucherDate: moment(state?.selectedRow?.voucherDate),
			});
		} else {
			form.resetFields();
		}
	}, [state?.selectedRow, form]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_VOUCHER === API_STATUS.SUCCESS || globalRedux.apiStatus.EDIT_VOUCHER === API_STATUS.SUCCESS) {
			setState({ ...state, visible: false });
			refreshList();
			dispatch(resetApiStatus(state?.selectedRow ? ACTIONS.EDIT_VOUCHER : ACTIONS.ADD_VOUCHER));
		}
	}, [globalRedux.apiStatus, dispatch, setState, state, refreshList]);

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux?.selectedOrganization?._id,
			...values,
		};
		dispatch(postApi(request, ACTIONS.ADD_VOUCHER));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}voucher/${state?.selectedRow?._id}`;
		dispatch(putApi(data, ACTIONS.EDIT_VOUCHER, url));
	};

	const loading = globalRedux.apiStatus.ADD_VOUCHER === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_VOUCHER === API_STATUS.PENDING;

	return (
		<AddVoucherPresentational
			{...{
				state,
				setState,
				classes,
				loading,
				form,
				handleSubmit,
				handleEdit,
				voucherHeads,
				accountBooks,
			}}
		/>
	);
};

export default AddVoucherFunctional;
