
function setAttendances(data){
    return{
        type : "SET_ATTENDANCES",
        payload : data
    }
}

export {  
    setAttendances,
}