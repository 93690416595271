import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objToQs } from 'helpers';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS } from 'constants/app-constants';
import { getApi } from 'redux/sagas/getApiDataSaga';
import TimesheetPresentational from './timesheet-presentational';

const TimesheetFunctional = () => {
	const [selectedBranchId, setSelectedBranchId] = useState('');
	// const [details, setDetails] = useState({
	// 	date: '',
	// 	takeName: '',
	// 	project: '',
	// 	module: '',
	// 	time: '',
	// });
	const projects = useSelector((state) => state?.projectRedux?.projects);
	const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	const dashboardData = useSelector(({ dashboardRedux }) => dashboardRedux?.dashboard);
	const tableData = dashboardData?.totalTickets?.map((ticket) => ({
		Username:
			ticket?.userInfo?.firstName || ticket?.userInfo?.lastName
				? `${ticket?.userInfo?.firstName || ''} ${ticket?.userInfo?.lastName || ''}`
				: 'Unassigned',
		Open: ticket?.statusCount?.Open || 0,
		Completed: ticket?.statusCount?.Completed || 0,
		Accepted: ticket?.statusCount?.Accepted || 0,
		Pending: ticket?.statusCount?.Pending || 0,
		InProgress: ticket?.statusCount?.InProgress || 0,
	}));
	const branches = useSelector(({ globalRedux: { branches } }) => branches);
	const dispatch = useDispatch();

	const getProjects = useCallback(() => {
		let url = `${SERVER_IP}project/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_PROJECTS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const getTimesheetData = useCallback(() => {
		let url = `${SERVER_IP}dashboard/?${objToQs({
			orgId: globalRedux?.selectedOrganization?._id,
			areaId: selectedBranchId,
		})}`;
		dispatch(getApi(ACTIONS.GET_DASHBOARD_DATA, url));
	}, [dispatch, globalRedux?.selectedOrganization?._id, selectedBranchId]);

	useEffect(() => {
		getTimesheetData();
		getProjects()
	}, [getTimesheetData, getProjects, selectedBranchId]);

	return <TimesheetPresentational {...{ dashboardData, tableData, loginRedux, branches, setSelectedBranchId, projects }} />;
};

export default TimesheetFunctional;
