import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col, Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
// import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { LogoComponent } from 'components/side-bar';
// import loginImg from 'assets/images/login1.jpg';
// import LottieComponent from 'components/lottie-component';
// import LottieFile from 'assets/lottie-files';
import LoginWithOTP from './login_with_otp';
import './login.scss';

// const settings = {
// 	dots: true,
// 	// fade: true,
// 	speed: 2000,
// 	slidesToShow: 1,
// 	slidesToScroll: 1,
// 	autoplay: true,
// 	autoplaySpeed: 2500,
// 	infinite: true,
// 	pauseOnHover: false,
// };

const { TabPane } = Tabs;

const FormProps = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 24,
	},
};

const Login = () => {
	const [form] = Form.useForm();
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!!loginRedux.token && loginRedux.isLogged && !globalRedux?.selectedOrganization?._id) {
			navigate('/organization');
		}
	}, [loginRedux.token, loginRedux.isLogged, globalRedux?.selectedOrganization?._id, navigate]);

	const login = (data) => dispatch(postApi(data, 'LOGIN'));

	return (
		<Row justify="center" align="middle" className="login_container" style={{ height: '100vh', backgroundColor: '#fff' }}>
			{/* <Col xl={12} md={12} className="login_image">
				<div style={{ fontWeight: 'bold', fontSize: 30, color: '#000', paddingBottom: 30 }}>
					Welcome to Zopay<span style={{ color: '#21b478', paddingLeft: 5 }}>Books</span>
				</div>
				<div style={{ fontWeight: 'bold', fontSize: 30, color: '#000', padding: '0 20px', textAlign: 'center' }}>
					#1 All in one application for In office, Mobile and Remote Team.
				</div>
				<div style={{ fontWeight: 'bold', fontSize: 20, color: '#000', padding: '0 20px', textAlign: 'center' }}>
					Sync all your Tickets, Invoices, Projects and Tasks in One App.
				</div>
				<LottieComponent file={LottieFile.Analytics} height="80%" />
			</Col> */}
			<Col xl={10} md={10} className="login_area">
				<Row justify="center" align="middle" style={{ height: '100%' }}>
					<Col xl={20} md={20} className="left_area">
						<LogoComponent style={{ fontSize: 30, color: '#000' }} />
						<Col span={24}>
							<div style={{ fontWeight: 'bold', fontSize: 30, color: '#000' }}>
								Welcome <span style={{ color: '#21b478' }}>back!</span>
							</div>
							<div style={{ color: 'lightgray' }}>Welcome back! Please enter your details.</div>
						</Col>
						<Col xl={24} style={{ paddingTop: 20, minHeight: '45vh' }}>
							<Tabs defaultActiveKey="LOGIN_WITH_PASSWORD">
								<TabPane tab={'Login with OTP'} key={'LOGIN_WITH_OTP'}>
									<LoginWithOTP />
								</TabPane>
								<TabPane tab={'Login with Password'} key={'LOGIN_WITH_PASSWORD'}>
									<Form
										requiredMark={false}
										colon={false}
										layout="vertical"
										labelAlign="left"
										form={form}
										onFinish={login}
										{...FormProps}>
										<Form.Item
											label="Mobile"
											name="mobile"
											hasFeedback
											className="hideFormLabel"
											rules={[
												{
													required: true,
													message: 'Please enter your Mobile',
												},
											]}>
											<Input placeholder="Mobile" maxLength={10} />
										</Form.Item>
										<Form.Item
											label="Password"
											name="password"
											hasFeedback
											className="hideFormLabel"
											rules={[
												{
													required: true,
													message: 'Please enter your Password',
												},
											]}>
											<Input.Password placeholder="Password" visibilityToggle />
										</Form.Item>
										<Form.Item>
											<Button
												style={{ width: '100%', fontWeight: 'bold' }}
												type="primary"
												htmlType="submit"
												disabled={globalRedux.apiStatus.LOGIN === 'PENDING'}
												loading={globalRedux.apiStatus.LOGIN === 'PENDING'}>
												LOGIN
											</Button>
										</Form.Item>
									</Form>
								</TabPane>
							</Tabs>
							<Row>
								<Col>
									<Row>
										{/* <Link className="nav-link" to="/forgot-password" style={{ fontSize: '12px' }}>
														Forgot Password?
													</Link> */}
										<br />
									</Row>
								</Col>
								{/* <Col>
									<Row>
										<Link className="nav-link" to="/register" style={{ fontSize: '12px', textAlign: 'right' }}>
											New User?
										</Link>
									</Row>
								</Col> */}
							</Row>
						</Col>
					</Col>
					{/* <Col xl={12} md={12} className="right_area">
						<Slider {...settings}>
							<div className="slider_item">
								<img src="https://accounts.zoho.com/v2/components/images/passwordless_illustration2x.png" alt="login_image" />
								<div className="title">Passwordless sign-in</div>
								<p className="para">
									Move away from risky passwords and experience one-tap access to Zoho. Download and install OneAuth.
								</p>
							</div>
							<div className="slider_item">
								<img src="https://accounts.zoho.com/v2/components/images/mfa_illustration2x.png" alt="login_image" />
								<div className="title">MFA for all accounts</div>
								<p className="para">
									Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.
								</p>
							</div>
						</Slider>
					</Col> */}
				</Row>
			</Col>
		</Row>
	);
};

export default Login;
