
function setHolidays(data){
    return{
        type : "SET_HOLIDAYS",
        payload : data
    }
}

export {  
    setHolidays,
}