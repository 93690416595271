import React, { useCallback } from 'react';
import moment from 'moment';
import { Button, Select, Modal, Drawer, Row, Col, Input, Form } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import AddSerialModal from 'components/add-serial-modal';
import DatePicker from 'components/date-picker';

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const AddInventoryPresentational = ({
	state,
	setState,
	handleSubmit,
	columns,
	loading,
	editData,
	form,
	isValid,
	handleInputChange,
	selectedData,
	setSelectedData,
	tableData,
	setTableData,
	serialType
}) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState((state) => ({ ...state, visible: false }));
			},
			onCancel() {},
		});
	}, [setState]);

	return (
		<>
			<Drawer
				maskClosable={false}
				title={`${editData ? 'Edit' : 'Create'} Inventory`}
				placement="right"
				width={'75%'}
				open={state?.visible}
				destroyOnClose
				className="add_inventory"
				onClose={showConfirm}
				footer={
					<Row>
						<Col
							xl={{
								span: 9,
								offset: 15,
							}}
							md={12}>
							<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
								<Col>
									<Button onClick={() => setState({ ...state, visible: false })}>Cancel</Button>
								</Col>
								<Col>
									<Button disabled={!isValid} loading={loading} type="primary" htmlType="submit" onClick={() => form.submit()}>
										{`${editData ? 'Update' : 'Submit'}`}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				}>
				<Row justify="start">
					<Col xl={24} md={24} className="inventory_table_container">
						<TableComponent
							{...{
								columns,
								dataSource: tableData,
								pagination: false,
								// footer: () => (
								// 	<Row style={{ fontSize: 14 }}>
								// 		<Col xl={18} md={18}>
								// 			<Col xl={24} md={24} className="bold" style={{ paddingRight: 10 }}>
								// 				Amount in words
								// 			</Col>
								// 			<Col xl={24} md={24}>
								// 				{inWords(roundOff?.value)}
								// 			</Col>
								// 		</Col>
								// 		<Col xl={6} md={6}>
								// 			<Row>
								// 				<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
								// 					Sub Total
								// 				</Col>
								// 				<Col xl={1} md={1}>
								// 					:
								// 				</Col>
								// 				<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
								// 					{parseFloat(totalAmount).toFixed(2)}
								// 				</Col>
								// 			</Row>
								// 			<Row>
								// 				<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
								// 					Round Off (+/-)
								// 				</Col>
								// 				<Col xl={1} md={1}>
								// 					:
								// 				</Col>
								// 				<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
								// 					{roundOff?.remain || 0}
								// 				</Col>
								// 			</Row>
								// 			<Row>
								// 				<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
								// 					Grand Total
								// 				</Col>
								// 				<Col xl={1} md={1}>
								// 					:
								// 				</Col>
								// 				<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
								// 					{parseFloat(roundOff?.value).toFixed(2)}
								// 				</Col>
								// 			</Row>
								// 		</Col>
								// 	</Row>
								// ),
								title: () => (
									<Row justify="space-between">
										<Col xl={24} md={24}>
											<Form
												labelAlign="left"
												name="add-inventory"
												className="required_in_right"
												form={form}
												onFinish={handleSubmit}
												{...layer1FormCol}>
												<Row gutter={[20, 20]}>
													<Col xl={12} md={12}>
														<Form.Item
															label="Mode"
															name="inventoryMode"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select placeholder="Select Mode">
																{["Qty Adjustment", "Value Adjustment"]?.map((value) => (
																	<Select.Option value={value}>{value}</Select.Option>
																))}
															</Select>
														</Form.Item>
														<Form.Item
															label="Stock type"
															name="stockType"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select placeholder="Select Stock type">
																{['Add', 'Reduce']?.map((value) => (
																	<Select.Option value={value}>{value}</Select.Option>
																))}
															</Select>
														</Form.Item>
														<Form.Item
															label="Reason"
															name="reason"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select placeholder="Select Reason">
																{['Opening Stock', 'Production Stock', 'Damaged']?.map((value) => (
																	<Select.Option value={value}>{value}</Select.Option>
																))}
															</Select>
														</Form.Item>
														<Form.Item
															label="Date"
															name="date"
															initialValue={moment()}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
														</Form.Item>
													</Col>
													<Col xl={12} md={12}>
														<Form.Item
															label="Reference No"
															name="referenceNumber"
															rules={[
																{
																	required: false,
																	message: 'This Field is required!',
																},
															]}>
															<Input placeholder="Enter Reference No" />
														</Form.Item>
														<Form.Item
															label="Remarks"
															name="remarks"
															initialValue={''}
															rules={[
																{
																	required: false,
																	message: 'This Field is required!',
																},
															]}>
															<Input.TextArea placeholder="Enter Remarks" />
														</Form.Item>
													</Col>
												</Row>
											</Form>
										</Col>
									</Row>
								),
							}}
						/>
					</Col>
				</Row>
			</Drawer>
			<AddSerialModal
				visible={!!selectedData}
				handleInputChange={handleInputChange}
				selectedData={selectedData}
				setSelectedData={setSelectedData}
				tableData={tableData}
				setTableData={setTableData}
				serialType={serialType}
			/>
		</>
	);
};

export default AddInventoryPresentational;
