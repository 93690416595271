import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import DepartmentsListPresentational from './departments-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const DepartmentsListFunctional = React.memo(() => {
	const departmentRedux = useSelector((state) => state.departmentRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [departmentAddModal, setDepartmentAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(departmentRedux.departments);
	const [editDepartment, setEditDepartment] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getDepartments = useCallback(() => {
		let url = `${SERVER_IP}department?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_DEPARTMENTS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getDepartments();
	}, [getDepartments]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_DEPARTMENT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_DEPARTMENT'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getDepartments();
		}
	}, [globalRedux.apiStatus, dispatch, getDepartments]);

	useEffect(() => {
		setTableData(departmentRedux.departments);
	}, [departmentRedux.departments]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((department) => {
			return (
				(department?.departmentName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(department?.remarks || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditDepartment(rowData);
		setDepartmentAddModal(true);
	};

	const handleAddDepartment = () => {
		setEditDepartment(null);
		setDepartmentAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'departmentNumber',
			key: 'departmentNumber',
			sorter: (a, b) => a?.departmentNumber - b?.departmentNumber,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Department Name',
			dataIndex: 'departmentName',
			key: 'departmentName',
			sorter: (a, b) => a?.departmentName?.localeCompare(b?.departmentName),
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			sorter: (a, b) => a?.remarks?.localeCompare(b?.remarks),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'displayName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_DEPARTMENTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setDepartmentAddModal(false);
		setEditDepartment(null);
	}, [setDepartmentAddModal, setEditDepartment]);

	return (
		<DepartmentsListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddDepartment,
				currentPage,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				departmentAddModal,
				setDepartmentAddModal,
				refreshList: getDepartments,
				editDepartment,
				handleClose,
			}}
		/>
	);
});

export default DepartmentsListFunctional;
