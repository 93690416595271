import React, { useEffect, useCallback } from 'react';
import { Row, Input, Button, Form, Drawer, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';

const AddEmployee = ({ employeeAddModal, width = '40%', editEmployee, setEmployeeAddModal, refreshList, handleClose }) => {
	const { selectedOrganization, apiStatus } = useSelector((state) => state.globalRedux);
	const shifts = useSelector((state) => state?.shiftsRedux?.shifts || []);
	const designations = useSelector((state) => state?.designationRedux?.designations || []);
	const departments = useSelector((state) => state?.departmentRedux?.departments || []);
	const form = Form.useForm()[0];
	const dispatch = useDispatch();

	useEffect(() => {
		if (editEmployee) {
			form.setFieldsValue({
				departmentId: editEmployee?.departmentId?._id,
				designationId: editEmployee?.designationId?._id,
				shiftId: editEmployee?.shiftId?._id,
				empId: editEmployee?.empId,
				employeeName: editEmployee?.employeeName,
				email: editEmployee?.email,
				mobile: editEmployee?.mobile,
				aadharCard: editEmployee?.aadharCard,
				panCard: editEmployee?.panCard,
				monthlySalary: editEmployee?.monthlySalary,
				remarks: editEmployee?.remarks,
			});
		} else {
			form?.resetFields();
		}
	}, [editEmployee, form]);

	const handleSubmit = (values) => {
		const data = {
			orgId: selectedOrganization?._id,
			...values,
		};
		const apiAction = !editEmployee ? 'ADD_EMPLOYEE' : 'EDIT_EMPLOYEE';
		const url = !editEmployee ? '' : `${SERVER_IP}employee/${editEmployee._id}?orgId=${selectedOrganization?._id}`;
		dispatch(!editEmployee ? postApi(data, apiAction) : putApi(data, apiAction, url));
	};

	const getDepartments = useCallback(() => {
		let url = `${SERVER_IP}department?orgId=${selectedOrganization?._id}`;
		dispatch(getApi('GET_DEPARTMENTS', url));
	}, [dispatch, selectedOrganization?._id]);

	const getDesignations = useCallback(() => {
		let url = `${SERVER_IP}designation?orgId=${selectedOrganization?._id}`;
		dispatch(getApi('GET_DESIGNATIONS', url));
	}, [dispatch, selectedOrganization?._id]);

	const getShifts = useCallback(() => {
		let url = `${SERVER_IP}shift?orgId=${selectedOrganization?._id}`;
		dispatch(getApi('GET_SHIFTS', url));
	}, [dispatch, selectedOrganization?._id]);

	useEffect(() => {
		getDepartments();
		getDesignations();
		getShifts();
	}, [getDepartments, getDesignations, getShifts]);

	useEffect(() => {
		if (apiStatus.ADD_EMPLOYEE === 'SUCCESS' || apiStatus.EDIT_EMPLOYEE === 'SUCCESS') {
			dispatch(resetApiStatus(editEmployee ? 'EDIT_EMPLOYEE' : 'ADD_EMPLOYEE'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [apiStatus, editEmployee, setEmployeeAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = apiStatus.ADD_EMPLOYEE === API_STATUS.PENDING || apiStatus.EDIT_EMPLOYEE === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editEmployee ? 'Edit' : 'New'} Employee`}
			width={width || '40%'}
			open={employeeAddModal}
			closable
			onClose={() => setEmployeeAddModal(false)}
			destroyOnClose={true}>
			<Row style={{ marginTop: 0 }}>
				<Form
					name="add-employee"
					className="required_in_right"
					style={{ width: '100%' }}
					labelAlign="left"
					form={form}
					onFinish={handleSubmit}
					initialValues={{}}
					{...layer1FormCol}>
					<Form.Item
						label="Department"
						name="departmentId"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
								option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Select Department">
							{departments?.map((department) => (
								<Select.Option key={department?.departmentNumber} value={department._id}>
									{department?.departmentName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Designation"
						name="designationId"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
								option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Select Designation">
							{designations?.map((designation) => (
								<Select.Option key={designation?.designationNumber} value={designation._id}>
									{designation?.designationName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Shift"
						name="shiftId"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
								option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Select Shift">
							{shifts?.map((shift) => (
								<Select.Option key={shift?.shiftNumber} value={shift._id}>
									{shift?.shiftName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Employee ID"
						name="empId"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input placeholder="Employee ID" />
					</Form.Item>
					<Form.Item
						label="Employee Name"
						name="employeeName"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input placeholder="Employee Name" />
					</Form.Item>
					<Form.Item
						label="Mobile"
						name="mobile"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input placeholder="Mobile" />
					</Form.Item>
					<Form.Item
						label="Email"
						name="email"
						rules={[
							{
								required: false,
								message: 'This Field is required!',
							},
						]}>
						<Input placeholder="Email" />
					</Form.Item>
					<Form.Item label="Aadhar Card" name="aadharCard">
						<Input placeholder="Aadhar Card" />
					</Form.Item>
					<Form.Item label="Pan Card" name="panCard">
						<Input placeholder="Pan Card" />
					</Form.Item>
					<Form.Item
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}
						label="Monthly Salary"
						name="monthlySalary">
						<Input placeholder="Monthly Salary" />
					</Form.Item>
					<Form.Item label="Remarks" name="remarks">
						<Input.TextArea placeholder="Remarks" />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setEmployeeAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editEmployee ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Drawer>
	);
};

export default AddEmployee;
