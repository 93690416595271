
function setEmployees(data){
    return{
        type : "SET_EMPLOYEES",
        payload : data
    }
}

export {  
    setEmployees,
}