import React from 'react';
import moment from 'moment';
import { Pagination, Row, Col, Button, Form, Select, Input } from 'antd';
import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const { RangePicker } = DatePicker;

const RealTimeReportPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	getData,
	form,
	fetchingData,
	handleClear,
	setSelectedRecordToPrint,
	employees,
}) => {
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					loading={fetchingData}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col xl={8} md={8}>
								<Form
									labelAlign="left"
									name="unit-sections"
									className="required_in_right"
									form={form}
									onFinish={getData}
									{...layer1FormCol}>
									<Row gutter={[20, 0]}>
										<Col xl={24} md={24}>
											<Row>
												<Col span={24}>
													<Form.Item
														label="Dates"
														name="dates"
														initialValue={[moment(), moment()]}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<RangePicker
															format={[DATE_FORMAT.DD_MM_YYYY, DATE_FORMAT.DD_MM_YYYY]}
															style={{ width: '100%' }}
														/>
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item
														label="Employee ID"
														name="employeeId"
														rules={[
															{
																required: false,
																message: 'This Field is required!',
															},
														]}>
														<Select
															allowClear
															showSearch
															optionFilterProp="children"
															filterOption={(input, option) =>
																option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
																option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
															}
															placeholder="Select Employee">
															{employees?.map((employee) => (
																<Select.Option key={employee?.employeeNumber} value={employee._id}>
																	{employee?.employeeName}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24}>
											<Row>
												<Col span={24}></Col>
												<Col span={24}>
													<Form.Item label="EMP ID" rules={[{ required: false }]} name="empId">
														<Input placeholder="EMP ID" />
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24}>
											<Row gutter={[10, 0]}>
												<Col span={24}>
													<Row gutter={[10, 10]}>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
																Clear
															</Button>
														</Col>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
																Submit
															</Button>
														</Col>
														{/* {filteredData?.length > 0 && (
															<Col span={8}>
																<Button
																	onClick={() =>
																		setSelectedRecordToPrint({ ...form.getFieldsValue(), data: filteredData })
																	}
																	type="primary"
																	style={{ width: '100%' }}>
																	Print
																</Button>
															</Col>
														)} */}
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default RealTimeReportPresentational;
