let initialState = {
	holidays: [],
};

const holidayReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_HOLIDAYS':
			return {
				...state,
				holidays: action.payload,
			};
		default:
			return state;
	}
};

export { holidayReducer };
