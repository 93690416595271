import React, { useEffect, useCallback } from 'react';
import { Row, Input, Button, Form, Drawer, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'components/date-picker';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { getApi } from 'redux/sagas/getApiDataSaga';
import moment from 'moment';

const AddAttendance = ({ attendanceAddModal, width = '40%', editAttendance, setAttendanceAddModal, refreshList, handleClose }) => {
	const { selectedOrganization, apiStatus } = useSelector((state) => state.globalRedux);
	const employees = useSelector((state) => state?.employeeRedux?.employees);
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const getEmployees = useCallback(() => {
		let url = `${SERVER_IP}employee?orgId=${selectedOrganization?._id}`;
		dispatch(getApi('GET_EMPLOYEES', url));
	}, [dispatch, selectedOrganization?._id]);

	useEffect(() => {
		getEmployees();
	}, [getEmployees]);

	useEffect(() => {
		if (editAttendance) {
			form.setFieldsValue({
				employeeId: editAttendance?.employeeId?._id,
				checkIn: moment(editAttendance?.checkIn),
				checkOut: moment(editAttendance?.checkOut),
				workingHours: editAttendance?.workingHours,
				overtime: editAttendance?.overtime,
				remarks: editAttendance?.remarks,
			});
		} else {
			form?.resetFields();
		}
	}, [editAttendance, form]);

	const handleSubmit = (values) => {
		const checkInMoment = moment(values.checkIn);
		const checkOutMoment = moment(values.checkOut);

		// Calculate working hours
		const workingHours = Math.min(checkOutMoment.diff(checkInMoment, 'hours', true), 8);

		// Calculate overtime hours
		const overtime = Math.max(checkOutMoment.diff(checkInMoment, 'hours', true) - 8, 0);

		// console.log(`Working Hours: ${workingHours.toFixed(2)} hours`);
		// console.log(`Overtime Hours: ${overtime.toFixed(2)} hours`);

		const data = {
			orgId: selectedOrganization?._id,
			...values,
			inputType: 'Manual',
			remarks: values?.remarks || '',
			workingHours: workingHours.toFixed(2),
			overtime: overtime.toFixed(2),
		};
		// console.log("🚀 ~ file: index.js:60 ~ handleSubmit ~ data:", data)

		const apiAction = !editAttendance ? 'ADD_ATTENDANCE' : 'EDIT_ATTENDANCE';
		const url = !editAttendance ? '' : `${SERVER_IP}attendance/${editAttendance._id}?orgId=${selectedOrganization?._id}`;
		dispatch(!editAttendance ? postApi(data, apiAction) : putApi(data, apiAction, url));
	};

	useEffect(() => {
		if (apiStatus.ADD_ATTENDANCE === 'SUCCESS' || apiStatus.EDIT_ATTENDANCE === 'SUCCESS') {
			dispatch(resetApiStatus(editAttendance ? 'EDIT_ATTENDANCE' : 'ADD_ATTENDANCE'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [apiStatus, editAttendance, setAttendanceAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = apiStatus.ADD_ATTENDANCE === API_STATUS.PENDING || apiStatus.EDIT_ATTENDANCE === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editAttendance ? 'Edit' : 'New'} Attendance`}
			width={width || '40%'}
			open={attendanceAddModal}
			closable
			onClose={() => setAttendanceAddModal(false)}
			destroyOnClose={true}>
			<Row style={{ marginTop: 0 }}>
				<Form
					name="add-attendance"
					className="required_in_right"
					style={{ width: '100%' }}
					labelAlign="left"
					form={form}
					onFinish={handleSubmit}
					initialValues={{}}
					{...layer1FormCol}>
					<Form.Item
						label="Employee ID"
						name="employeeId"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
								option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Select Employee">
							{employees?.map((employee) => (
								<Select.Option key={employee?.employeeNumber} value={employee._id}>
									{employee?.employeeName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Check In"
						name="checkIn"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<DatePicker
							format={DATE_FORMAT.DD_MM_YYYY_HH_MM_A}
							style={{ width: '100%' }}
							placeholder="Check In"
							showTime={{ use12Hours: true }}
						/>
					</Form.Item>
					<Form.Item
						label="Check Out"
						name="checkOut"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<DatePicker
							format={DATE_FORMAT.DD_MM_YYYY_HH_MM_A}
							style={{ width: '100%' }}
							placeholder="Check Out"
							showTime={{ use12Hours: true }}
						/>
					</Form.Item>
					{/* <Form.Item label="Working Hours" name="workingHours">
						<Input disabled placeholder="Working Hours" />
					</Form.Item>
					<Form.Item label="Overtime" name="overtime">
						<Input disabled placeholder="Overtime" />
					</Form.Item> */}
					<Form.Item label="Remarks" name="remarks">
						<Input.TextArea placeholder="Remarks" />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setAttendanceAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editAttendance ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Drawer>
	);
};

export default AddAttendance;
