import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import EmployeeListPresentational from './employee-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const EmployeeListFunctional = React.memo(() => {
	const employeeRedux = useSelector((state) => state.employeeRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [employeeAddModal, setEmployeeAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(employeeRedux.employees);
	const [editEmployee, setEditEmployee] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getEmployees = useCallback(() => {
		let url = `${SERVER_IP}employee?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_EMPLOYEES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getEmployees();
	}, [getEmployees]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_EMPLOYEE === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_EMPLOYEE'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getEmployees();
		}
	}, [globalRedux.apiStatus, dispatch, getEmployees]);

	useEffect(() => {
		setTableData(employeeRedux.employees);
	}, [employeeRedux.employees]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((employee) => {
			return (
				(employee?.empId || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.shiftId?.shiftName || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.designationId?.designationName || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.departmentId?.departmentName || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.employeeName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.aadharCard || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.panCard || '')?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
				(employee?.remarks || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditEmployee(rowData);
		setEmployeeAddModal(true);
	};

	const handleAddEmployee = () => {
		setEditEmployee(null);
		setEmployeeAddModal(true);
	};

	const column = useMemo(() => [
		{
			title: '#',
			dataIndex: 'employeeNumber',
			key: 'employeeNumber',
			sorter: (a, b) => a?.employeeNumber - b?.employeeNumber,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Employee Name',
			dataIndex: 'employeeName',
			key: 'employeeName',
			sorter: (a, b) => a?.employeeName?.localeCompare(b?.employeeName),
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'Emp ID',
			dataIndex: 'empId',
			key: 'empId',
			sorter: (a, b) => a?.empId?.localeCompare(b?.empId),
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile',
			sorter: (a, b) => a?.mobile?.localeCompare(b?.mobile),
			// sorter: (a, b) => a?.mobile - b?.mobile,
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: (a, b) => a?.email?.localeCompare(b?.email),
			align: 'left',
			width: '15%',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Department',
			dataIndex: 'departmentId',
			sorter: (a, b) => a?.departmentId?.departmentName?.localeCompare(b?.departmentId?.departmentName),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.departmentName} />,
		},
		{
			title: 'Designation',
			dataIndex: 'designationId',
			sorter: (a, b) => a?.designationId?.designationName?.localeCompare(b?.designationId?.designationName),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.designationName} />,
		},
		{
			title: 'Shift',
			dataIndex: 'shiftId',
			sorter: (a, b) => a?.shiftId?.shiftName?.localeCompare(b?.shiftId?.shiftName),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.shiftName} />,
		},
		{
			title: 'Salary',
			dataIndex: 'monthlySalary',
			sorter: (a, b) => a?.monthlySalary - b?.monthlySalary,
			// sorter: (a, b) => a?.monthlySalary?.localeCompare(b?.monthlySalary),
			align: 'right',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={parseFloat(value || 0).toFixed(2)} />,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			sorter: (a, b) => a?.remarks?.localeCompare(b?.remarks),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'displayName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	], [searchKey])

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_EMPLOYEES === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setEmployeeAddModal(false);
		setEditEmployee(null);
	}, [setEmployeeAddModal, setEditEmployee]);

	const exportData = useMemo(() => {
		const data = {
			headers: (column || []).slice(0, -1)?.map((col) => col?.title) || [],
			data: [
				...(filteredData.map((employee) => ({
					[column[0]?.title]: employee?.employeeNumber,
					[column[1]?.title]: employee?.employeeName,
					[column[2]?.title]: employee?.empId,
					[column[3]?.title]: employee?.mobile,
					[column[4]?.title]: employee?.email,
					[column[5]?.title]: employee?.departmentId?.departmentName,
					[column[6]?.title]: employee?.designationId?.designationName,
					[column[7]?.title]: employee?.shiftId?.shiftName,
					[column[8]?.title]: parseFloat(employee?.monthlySalary || 0).toFixed(),
					[column[9]?.title]: employee?.remarks,
				})) || []),
			],
		};
		return data;
	}, [filteredData, column]);


	return (
		<EmployeeListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddEmployee,
				currentPage,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				employeeAddModal,
				setEmployeeAddModal,
				refreshList: getEmployees,
				editEmployee,
				handleClose,
				exportData,
			}}
		/>
	);
});

export default EmployeeListFunctional;
