let initialState = {
	employees: [],
};

const employeeReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_EMPLOYEES':
			return {
				...state,
				employees: action.payload,
			};
		default:
			return state;
	}
};

export { employeeReducer };
