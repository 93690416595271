import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import ShiftsListPresentational from './shifts-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const ShiftsListFunctional = React.memo(() => {
	const shiftsRedux = useSelector((state) => state.shiftsRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [shiftAddModal, setShiftAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(shiftsRedux.shifts);
	const [editShift, setEditShift] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getShifts = useCallback(() => {
		let url = `${SERVER_IP}shift?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_SHIFTS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getShifts();
	}, [getShifts]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_SHIFT === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_SHIFT'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getShifts();
		}
	}, [globalRedux.apiStatus, dispatch, getShifts]);

	useEffect(() => {
		setTableData(shiftsRedux.shifts);
	}, [shiftsRedux.shifts]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((shift) => {
			return (
				(shift?.shiftName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(shift?.from || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(shift?.to || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(shift?.remarks || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditShift(rowData);
		setShiftAddModal(true);
	};

	const handleAddShift = () => {
		setEditShift(null);
		setShiftAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'shiftNumber',
			key: 'shiftNumber',
			sorter: (a, b) => a?.shiftNumber - b?.shiftNumber,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Shift Name',
			dataIndex: 'shiftName',
			key: 'shiftName',
			sorter: (a, b) => a?.shiftName?.localeCompare(b?.shiftName),
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'From',
			dataIndex: 'from',
			key: 'from',
			sorter: (a, b) => a?.from - b?.from,
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={value?.toString()}
					/>
				</div>
			),
		},
		{
			title: 'To',
			dataIndex: 'to',
			key: 'to',
			sorter: (a, b) => a?.to - b?.to,
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={value?.toString()}
					/>
				</div>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			sorter: (a, b) => a?.remarks?.localeCompare(b?.remarks),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'displayName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_SHIFTS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setShiftAddModal(false);
		setEditShift(null);
	}, [setShiftAddModal, setEditShift]);

	return (
		<ShiftsListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddShift,
				currentPage,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				shiftAddModal,
				setShiftAddModal,
				refreshList: getShifts,
				editShift,
				handleClose,
			}}
		/>
	);
});

export default ShiftsListFunctional;
