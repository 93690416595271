import React, { useEffect, useCallback } from 'react';
import { Row, Input, Button, Form, Drawer } from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import DatePicker from 'components/date-picker';
import { getApi } from 'redux/sagas/getApiDataSaga';

const AddHoliday = ({ holidayAddModal, width = '40%', editHoliday, setHolidayAddModal, refreshList, handleClose }) => {
	const { selectedOrganization, apiStatus } = useSelector((state) => state.globalRedux);
	const form = Form.useForm()[0];
	const dispatch = useDispatch();

	useEffect(() => {
		if (editHoliday) {
			form.setFieldsValue({
				date: moment(editHoliday?.date),
				holidayFor: editHoliday?.holidayFor,
				remarks: editHoliday?.remarks,
			});
		} else {
			form?.resetFields();
		}
	}, [editHoliday, form]);

	const handleSubmit = (values) => {
		const data = {
		  orgId: selectedOrganization?._id,
		  ...values
		};
		const apiAction = !editHoliday ? 'ADD_HOLIDAY' : 'EDIT_HOLIDAY';
		const url = !editHoliday ? '' : `${SERVER_IP}holiday/${editHoliday._id}?orgId=${selectedOrganization?._id}`;
		dispatch(!editHoliday ? postApi(data, apiAction) : putApi(data, apiAction, url));
	  };
	  
	const getDepartments = useCallback(() => {
		let url = `${SERVER_IP}department?orgId=${selectedOrganization?._id}`;
		dispatch(getApi('GET_DEPARTMENTS', url));
	}, [dispatch, selectedOrganization?._id]);

	const getDesignations = useCallback(() => {
		let url = `${SERVER_IP}designation?orgId=${selectedOrganization?._id}`;
		dispatch(getApi('GET_DESIGNATIONS', url));
	}, [dispatch, selectedOrganization?._id]);

	const getShifts = useCallback(() => {
		let url = `${SERVER_IP}shift?orgId=${selectedOrganization?._id}`;
		dispatch(getApi('GET_SHIFTS', url));
	}, [dispatch, selectedOrganization?._id]);
	
	useEffect(() => {
		getDepartments();
		getDesignations();
		getShifts();
	}, [getDepartments, getDesignations, getShifts]);

	useEffect(() => {
		if (apiStatus.ADD_HOLIDAY === 'SUCCESS' || apiStatus.EDIT_HOLIDAY === 'SUCCESS') {
			dispatch(resetApiStatus(editHoliday ? 'EDIT_HOLIDAY' : 'ADD_HOLIDAY'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [apiStatus, editHoliday, setHolidayAddModal, dispatch, refreshList, handleClose, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = apiStatus.ADD_HOLIDAY === API_STATUS.PENDING || apiStatus.EDIT_HOLIDAY === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editHoliday ? 'Edit' : 'New'} Holiday`}
			width={width || '40%'}
			open={holidayAddModal}
			closable
			onClose={() => setHolidayAddModal(false)}
			destroyOnClose={true}>
			<Row style={{ marginTop: 0 }}>
				<Form
					name="add-holiday"
					className="required_in_right"
					style={{ width: '100%' }}
					labelAlign="left"
					form={form}
					onFinish={handleSubmit}
					initialValues={{
					}}
					{...layer1FormCol}>
					<Form.Item
						label="Date"
						name="date"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} placeholder="Date" />
					</Form.Item>
					<Form.Item
						label="Holiday For"
						name="holidayFor"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input placeholder="Holiday For"/>
					</Form.Item>
					<Form.Item label="Remarks" name="remarks">
						<Input.TextArea placeholder="Remarks" />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setHolidayAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editHoliday ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Drawer>
	);
};

export default AddHoliday;