
function setDepartments(data){
    return{
        type : "SET_DEPARTMENTS",
        payload : data
    }
}

export {  
    setDepartments,
}