
function setDesignations(data){
    return{
        type : "SET_DESIGNATIONS",
        payload : data
    }
}

export {  
    setDesignations,
}