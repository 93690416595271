import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import HolidayListPresentational from './holidays-list-presentational';
import moment from 'moment';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const HolidayListFunctional = React.memo(() => {
	const holidayRedux = useSelector((state) => state.holidayRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [holidayAddModal, setHolidayAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(holidayRedux.holidays);
	const [editHoliday, setEditHoliday] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getHolidays = useCallback(() => {
		let url = `${SERVER_IP}holiday?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_HOLIDAYS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getHolidays();
	}, [getHolidays]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_HOLIDAY === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_HOLIDAY'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getHolidays();
		}
	}, [globalRedux.apiStatus, dispatch, getHolidays]);

	useEffect(() => {
		setTableData(holidayRedux.holidays);
	}, [holidayRedux.holidays]);
	
	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((holiday) => {
			return (
				(holiday?.holidayId || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(holiday?.date).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(holiday?.holidayFor || '')?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
				(holiday?.remarks || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditHoliday(rowData);
		setHolidayAddModal(true);
	};

	const handleAddHoliday = () => {
		setEditHoliday(null);
		setHolidayAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'holidayNumber',
			key: 'holidayNumber',
			width: '5%',
			sorter: (a, b) => a?.holidayNumber - b?.holidayNumber,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			width: '8%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: 'Holiday For',
			dataIndex: 'holidayFor',
			sorter: (a, b) => a?.holidayFor?.localeCompare(b?.holidayFor),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			sorter: (a, b) => a?.remarks?.localeCompare(b?.remarks),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'displayName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_HOLIDAYS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setHolidayAddModal(false);
		setEditHoliday(null);
	}, [setHolidayAddModal, setEditHoliday]);

	return (
		<HolidayListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddHoliday,
				currentPage,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				holidayAddModal,
				setHolidayAddModal,
				refreshList: getHolidays,
				editHoliday,
				handleClose,
			}}
		/>
	);
});

export default HolidayListFunctional;
