import toast from 'react-hot-toast';
import { call, put, takeEvery } from 'redux-saga/effects';
import { setRegisterSuccess } from 'redux/reducers/register/registerActions';
import { RESTCallError, sendPostRequest } from './utils';
import { store } from '../store';
import { setApiStatus } from '../reducers/globals/globalActions';
import { setLoginSuccess } from '../reducers/login/loginActions';

function postApi(body, apiName, url, extras) {
	return {
		type: 'POST_API_DATA',
		url: url,
		body: body,
		apiName: apiName,
		extras: extras,
	};
}

function* postApiDataSaga() {
	yield takeEvery('POST_API_DATA', postApiDataWorker);
}

function* postApiDataWorker(param) {
	let apiName = param.apiName;
	try {
		store.dispatch(setApiStatus(apiName, 'PENDING'));
		const result = yield call(sendPostRequest, param);
		if (result.status >= 200 && result.status <= 204) {
			yield postApiDataSuccess(result.data, apiName, param.extras);
		} else if (result.status === 'Error') {
			toast.error(result?.error?.response?.data?.message || 'Something went wrong!');
			RESTCallError(result, apiName);
		}
	} catch (error) {
		
	}
}

function* postApiDataSuccess(response, apiName, extras) {
	switch (apiName) {
		case 'LOGIN':
			toast.success('Logged In!');
			yield put(setLoginSuccess(response));
			break;
		case 'VERIFY_OTP':
			toast.success('Logged In!');
			yield put(setLoginSuccess(response));
			break;
		case 'REGISTER':
			toast.success('Logged In!');
			yield put(setRegisterSuccess(response));
			break;
		case 'ADD_STUDENT':
			toast.success('Student Added Successfully!');
			break;
		case 'ADD_VOUCHER':
			toast.success('Voucher Added Successfully!');
			break;
		case 'ADD_RECEIPT':
			toast.success('Receipt Added Successfully!');
			break;
		case 'ADD_LOAD_IN':
			toast.success('Load In Added Successfully!');
			break;
		case 'ADD_ACCOUNT_BOOK':
			toast.success('Account book added successfully!');
			break;
		case 'ADD_PROJECT':
			toast.success('Project added successfully!');
			break;
		case 'ADD_VOUCHER_HEAD':
			toast.success('Voucher head added successfully!');
			break;
		case 'ADD_STAFF':
			toast.success('Staff added successfully!');
			break;
		case 'ADD_ASSETS':
			toast.success('Asset added successfully!');
			break;
		case 'ADD_VEHICLE':
			toast.success('Vehicle added successfully!');
			break;
		case 'ADD_VENDOR':
			toast.success('Vendor added successfully!');
			break;
		case 'ADD_INVOICE':
			toast.success('Invoice created successfully!');
			break;
		case 'ADD_CREDENTIAL':
			toast.success('Credential added successfully!');
			break;
		case 'ADD_CUSTOMER':
			toast.success('Customer added successfully!');
			break;
		case 'ADD_EMPLOYEE':
			toast.success('Employee added successfully!');
			break;
		case 'ADD_ATTENDANCE':
			toast.success('Attendance added successfully!');
			break;
		case 'ADD_HOLIDAY':
			toast.success('Holiday added successfully!');
			break;
		case 'ADD_DEPARTMENT':
			toast.success('Department added successfully!');
			break;
		case 'ADD_SHIFT':
			toast.success('Shift added successfully!');
			break;
		case 'ADD_USER':
			toast.success('User added successfully!');
			break;
		case 'MODIFY_USER_ROLE':
			toast.success('User role modified!');
			break;
		case 'ADD_PART_NUMBER':
			toast.success('PartNumber added successfully!');
			break;
		case 'ADD_BATCH':
			toast.success('Batch added successfully!');
			break;
		case 'ADD_CLASS':
			toast.success('Class added successfully!');
			break;
		case 'ADD_BATCH_BALANCE':
			toast.success('Updated!');
			break;
		case 'ADD_CATEGORY':
			toast.success('Category created successfully!');
			break;

		default:
			break;
	}
	yield put(setApiStatus(apiName, 'SUCCESS'));
}

export { postApi, postApiDataSaga };
