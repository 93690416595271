import React from 'react';
import { Drawer } from 'antd';
import AddVoucher from 'pages/master-component/AddVoucher';
import AddVehicle from 'pages/master-component/AddVehicle';
import AddCustomer from 'pages/master-component/AddCustomer';
import AddSalesPerson from 'pages/master-component/sales-person/add-sales-person';
import AddAccountBook from 'pages/master-component/acc-book/add-acc-book';
// import AddItem from 'pages/master-component/AddItem';
// import AddAsset from 'pages/master-component/AddAsset';
// import AddVendor from 'pages/master-component/AddVendor';
// import AddCredential from 'pages/master-component/AddCredential';

const AddDrawer = ({
	width,
	setSupplierAddModal = null,
	setAgentAddModal = null,
	getAgents = null,
	refreshList,
	getStaffs,
	getAssets,
	editVehicle,
	accBookAddModal,
	setAccBookAddModal,
	getAccountBooks,
	editAccountBooks,
	accountBookModal,
	setAccountBookModal,
	getVouchers,
	editVoucher,
	vehicleAddModal,
	setVehicleAddModal,
	getVehicle,
	getClass,
	getVendor,
	getCredential,
	customerAddModal,
	setCustomerAddModal,
	getCustomers,
	editCustomer,
	getItems,
	salesPersonAddModal,
	setSalesPersonAddModal,
	getSalesPersons,
	editSalesPerson,
}) => {
	// const showConfirm = useCallback(() => {
	// 	confirm({
	// 		title: 'Do you want to close this window?',
	// 		icon: <ExclamationCircleOutlined />,
	// 		content: 'You will be lost all the details you have entered here.',
	// 		onOk() {
	// 			toggleVisible(false);
	// 		},
	// 		onCancel() {
	//
	// 		},
	// 	});
	// }, [toggleVisible]);

	const handleClose = () => {
		setSupplierAddModal?.(false);
		setAgentAddModal?.(false);
		getAgents?.();
		refreshList?.();
		getStaffs?.();
		getAssets?.();
		getAccountBooks?.();
		getVouchers?.();
		getItems?.();
		getVehicle?.();
		getClass?.();
		getVendor?.();
		getCredential?.();
		getCustomers?.();
		getItems?.();
		getSalesPersons?.();
	};
	return (
		<Drawer
			className="customer-form"
			destroyOnClose
			placement="right"
			width={width || '40%'}
			onClose={
				() =>
					// setSupplierAddModal?.(false) ||
					// setAgentAddModal?.(false) ||
					// setStaffsAddModal?.(false) ||
					// setAssetsAddModal?.(false) ||
					// setAccBookAddModal?.(false) ||
					// setAccountBookModal?.(false) ||
					// setItemsAddModal?.(false) ||
					// setVehicleAddModal?.(false) ||
					// setClassAddModal?.(false) ||
					// setVendorAddModal?.(false) ||
					// setCredentialAddModal?.(false) ||
					setCustomerAddModal?.(false)
				// handleClose?.()
			}
			visible={customerAddModal || accBookAddModal || accountBookModal || vehicleAddModal || salesPersonAddModal}>
			{customerAddModal && <AddCustomer {...{ handleClose, editCustomer, setCustomerAddModal }} />}
			{vehicleAddModal && <AddVehicle {...{ handleClose, setVehicleAddModal, editVehicle }} />}
			{accBookAddModal && <AddAccountBook {...{ handleClose, editAccountBooks, setAccBookAddModal }} />}
			{accountBookModal && <AddVoucher {...{ handleClose, editVoucher, setAccountBookModal }} />}
			{salesPersonAddModal && <AddSalesPerson {...{ handleClose, editSalesPerson, setSalesPersonAddModal }} />}
		</Drawer>
	);
};

export default AddDrawer;
