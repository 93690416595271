import React from 'react';
import { Table } from 'antd';
import './style.scss';

const TableComponent = ({ columns, dataSource, className, bordered = true, rowKey, title, pagination, footer, onChange, loading, ...rest }) => {
	return (
		<Table
			{...{
				loading,
				className: `table-component ${className}`,
				// columns: loading
				// 	? columns.map((column) => {
				// 			return {
				// 				...column,
				// 				render: function renderPlaceholder() {
				// 					return <Skeleton key={column.dataIndex} title={true} active paragraph={false} style={{ width: '100%' }} />;
				// 				},
				// 			};
				// 	  })
				// 	: columns,
				columns,
				dataSource,
				bordered,
				rowKey,
				title,
				pagination,
				footer,
				onChange,
				...rest,
			}}
		/>
	);
};

export default TableComponent;
