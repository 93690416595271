import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDateFormat } from 'services/Utils';
import { DATE_FORMAT } from 'constants/app-constants';
import './real-time-report-to-print.scss';

const RealTimeReportToPrint = forwardRef((props, ref) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	const { voucherHeads = [] } = globalRedux;
	const { data, fromDate, toDate, transactionType, voucherHeadId } = props?.data || {};
	const voucherHead = voucherHeads?.find((voucherHead) => voucherHead?._id === voucherHeadId);

	const totalCredit = data?.filter((data) => data?.transactionType === 'Credit').reduce((acc, prev) => acc + prev?.amount, 0);
	const totalDebit = data?.filter((data) => data?.transactionType === 'Debit').reduce((acc, prev) => acc + prev?.amount, 0);

	return (
		<div className="invoice-pdf-container" ref={ref}>
			<div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '1rem' }}>Expense Report</div>
			<table cellPadding="0" cellSpacing="0">
				<tr>
					<td colSpan="3">
						<table border="1" className="top-heading">
							<tr>
								<td colSpan={'3'} style={{ width: '50%' }}>
									<div className="content">
										<div style={{ display: 'flex' }}>
											<div className="title" style={{ width: '50%' }}>
												Voucher: <span style={{ fontWeight: 'normal' }}>{voucherHead?.voucherheadName || 'All'}</span>
											</div>
											<div className="title" style={{ width: '50%', textAlign: 'right' }}>
												From Date:{' '}
												<span style={{ fontWeight: 'normal' }}>{moment(fromDate).format(DATE_FORMAT.DD_MM_YYYY)}</span>
											</div>
										</div>
										<div style={{ display: 'flex' }}>
											<div className="title" style={{ width: '50%' }}>
												Transaction type: <span style={{ fontWeight: 'normal' }}>{transactionType}</span>
											</div>
											<div className="title" style={{ width: '50%', textAlign: 'right' }}>
												To Date: <span style={{ fontWeight: 'normal' }}>{moment(toDate).format(DATE_FORMAT.DD_MM_YYYY)}</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td colSpan="3" style={{ padding: 0 }}>
									<table>
										<tr>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderLeftWidth: 0,
													textAlign: 'center',
												}}>
												S.No
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Voucher Date
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
											Voucher Head Name
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
											Transaction Type
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Amount
											</td>
										</tr>
										{(data || [])?.map((data, index) => (
											<tr>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														borderLeftWidth: 0,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.voucherNumber}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														padding: 5,
														height: 30,
														textAlign: 'center',
													}}>
													{moment(data?.voucherDate).format(DATE_FORMAT.DD_MM_YYYY)}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.voucherheadId?.voucherheadName}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.transactionType}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.amount}
												</td>
											</tr>
										))}
									</table>
								</td>
							</tr>
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td style={{ width: '50%' }}>
									<div className="content" style={{}}>
										<div>
											Printed by {loginRedux?.firstName} {loginRedux?.lastName}
										</div>
									</div>
									<div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div>
								</td>
								<td colSpan={'2'} style={{ width: '50%' }}>
									<div
										className="content"
										style={{
											display: 'flex',
											fontSize: '1rem',
											justifyContent: 'space-between',
										}}>
										<div className="title" style={{ textAlign: 'right' }}>
											Total Credit
										</div>
										<div className="title" style={{ textAlign: 'right' }}>
											{totalCredit}
										</div>
									</div>
									<div
										className="content"
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											fontSize: '1rem',
										}}>
										<div className="title" style={{ textAlign: 'right' }}>
											Total Debit
										</div>
										<div className="title" style={{ textAlign: 'right' }}>
											{totalDebit}
										</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default RealTimeReportToPrint;
