import React, {  } from 'react';
import { Row, Col, DatePicker, Input, Select, Button } from 'antd';
import Table from './Table';
import { tableHeaders, tableData } from './data';

let TIMES = [
	{
		label: '0.15',
		value: '0.15',
	},
	{
		label: '0.30',
		value: '0.30',
	},
];

// Calculate the sum of existing values
let currentSum = TIMES.reduce((sum, item) => sum + parseFloat(item.value), 0);

// Define the target sum
const targetSum = 3.0;

// Add objects with 15-minute intervals until the target sum is reached
while (currentSum < targetSum) {
	currentSum += 0.15; // Increment by 0.15
	const label = currentSum.toFixed(2);
	const value = label;
	TIMES.push({ label, value });
}

const DashboardPresentational = ({ dashboardData, loginRedux, branches, setSelectedBranchId, projects }) => {
	// const ref1 = useRef(null);
	// const ref2 = useRef(null);

	return (
		<Row className="timesheet_container">
			<Col xl={24}>
				<Row gutter={[10, 0]}>
					<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
						<Row className="detail_submit_container">
							<Col span={3}>
								<DatePicker style={{ width: '100% ' }} />
							</Col>
							<Col span={7}>
								<Input style={{ width: '100% ' }} placeholder="What have you done?" />
							</Col>
							<Col span={5}>
								<Select
									style={{ width: '100% ' }}
									// onChange={onChange}
									placeholder="Select Project">
									{projects.map((project) => (
										<Select.Option key={project?._id} value={project?._id}>
											{project?.title}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={5}>
								<Select
									style={{ width: '100% ' }}
									// onChange={onChange}
									placeholder="Select Module">
									{projects.map((project) => (
										<Select.Option key={project?._id} value={project?._id}>
											{project?.title}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={2}>
								<Select
									style={{ width: '100% ' }}
									// onChange={onChange}
									placeholder="Select Time">
									{TIMES.map((time) => (
										<Select.Option key={time?.value} value={time?.value}>
											{time?.label}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={2}>
								<Button style={{ width: '100%' }} type="primary">
									Save
								</Button>
							</Col>
						</Row>
					</Col>
					<Col style={{ height: 'calc(100vh - 93px)', overflowY:'scroll' }}>
						<Table headers={tableHeaders} data={tableData} />
						<Table headers={tableHeaders} data={tableData} />
						<Table headers={tableHeaders} data={tableData} />
						<Table headers={tableHeaders} data={tableData} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default DashboardPresentational;
