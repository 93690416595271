export const tableHeaders = ['Description', 'Project', 'Module', 'Task', "Worked", "Approved", "Efficiency", "Productivity"];

export const tableData = [
	{
		country: 'Brazil',
		code: 'BR',
		area: '8,515,767 km2',
		flag: '🇧🇷',
	},
];
