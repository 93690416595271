
function setShifts(data){
    return{
        type : "SET_SHIFTS",
        payload : data
    }
}

export {  
    setShifts,
}