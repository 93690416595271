import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'antd';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { DATE_FORMAT } from 'constants/app-constants';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { sendGetRequest } from 'redux/sagas/utils';
import { formQueryStringFromObject } from 'helpers';
import { getDateFormat } from 'services/Utils';
import RealTimeReportPresentational from './real-time-report-list-presentational';
import RealTimeReportToPrint from './real-time-report-to-print';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const RealTimeReportFunctional = React.memo(() => {
	const [state, setState] = useState({
		visible: false,
	});
	const [fetchingData, setFetchingData] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const employees = useSelector((state) => state?.employeeRedux?.employees);
	const componentRef = React.useRef();
	const [searchKey] = useState('');
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	
	const getEmployees = useCallback(() => {
		let url = `${SERVER_IP}employee?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_EMPLOYEES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getEmployees();
	}, [getEmployees]);

	const column = [
		{
			title: '#',
			dataIndex: 'dataNumber',
			key: 'dataNumber',
			sorter: (a, b) => a?.dataNumber - b?.dataNumber,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Employee Name',
			dataIndex: 'employeeId',
			key: 'employeeId',
			sorter: (a, b) => a?.employeeId?.employeeName?.localeCompare(b?.employeeId?.employeeName),
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.employeeName} />
				</div>
			),
		},
		{
			title: 'EMP ID',
			dataIndex: 'empId',
			sorter: (a, b) => a?.empId?.localeCompare(b?.empId),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Log Time',
			dataIndex: 'logTime',
			align: 'left',
			sorter: (a, b) => new Date(a.logTime) - new Date(b.logTime),
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Input Type',
			dataIndex: 'inputType',
			sorter: (a, b) => a?.inputType?.localeCompare(b?.inputType),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Type',
			dataIndex: 'type',
			sorter: (a, b) => a?.type?.localeCompare(b?.type),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'STGID',
			dataIndex: 'stgid',
			sorter: (a, b) => a?.stgid?.localeCompare(b?.stgid),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Operation ID',
			dataIndex: 'operationID',
			sorter: (a, b) => a?.operationID?.localeCompare(b?.operationID),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			align: 'left',
			sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
			render: (value) => getDateFormat(value),
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const getData = useCallback(
		async () => {
			const values = form.getFieldsValue()
			console.log("🚀 ~ file: real-time-report-list-functional.js:189 ~ values:", values)
			const string = formQueryStringFromObject({
				fromDate: moment(values?.dates[0]).format(DATE_FORMAT.YYYY_MM_DD),
				toDate: moment(values?.dates[1]).format(DATE_FORMAT.YYYY_MM_DD),
				employeeId: values?.employeeId,
				empId: values?.empId || '',
			});
			await setFetchingData(true);
			const res = await sendGetRequest(null, `${SERVER_IP}data?orgId=${globalRedux?.selectedOrganization?._id}&${string}`);
			await setTableData(res?.data?.data || []);
			await setFetchingData(false);
		},
		[setFetchingData, globalRedux?.selectedOrganization?._id]
	);

	useEffect(() => {
		getData()
	}, [getData])

	const handleClear = () => {
		setTableData([]);
		form.resetFields();
	};

	return (
		<>
			<div style={{ display: 'none' }}>
				<RealTimeReportToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<RealTimeReportPresentational
				{...{
					column,
					filteredData: tableData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					getData,
					form,
					fetchingData,
					handleClear,
					setSelectedRecordToPrint,
					employees
				}}
			/>
		</>
	);
});

export default RealTimeReportFunctional;
