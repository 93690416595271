import React from 'react';
import useStickyHeader from './useStickyHeader.js';
import './sticky-table.scss';

export default function Table({ headers = [], data = [] }) {
	const { tableRef, isSticky } = useStickyHeader();
	
	const renderHeader = () => (
		<thead>
			<tr>
				{headers.map((item) => (
					<th key={item}>{item}</th>
				))}
			</tr>
		</thead>
	);

	return (
		<div className="sticky-table">
			{isSticky && (
				/*
        .sticky will be the copy of table header while sticky 
        needed as otherwise table won't preserve columns width
        */
				<table
					className="sticky"
					style={{
						position: 'fixed',
						top: 70,
						left: 0,
						right: 0,
					}}>
					{renderHeader()}
				</table>
			)}
			<table ref={tableRef}>
				{renderHeader()}
				<tbody>
					{data.map((item) => (
						<tr key={item.code}>
							<td>{item.country}</td>
							<td>{item.code}</td>
							<td>{item.area}</td>
							<td>{item.flag}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
