import React, {
	useRef,
	useEffect,
} from 'react';
import {
	Row,
	Col,
	Button,
	Spin
	// Tour,
	// Select
} from 'antd';
import { BsFillPersonFill } from 'react-icons/bs';
import { ReloadOutlined } from '@ant-design/icons';
import AnimatedNumber from 'components/animated-number';
import DatePicker from 'components/date-picker';
import { DATE_FORMAT } from 'constants/app-constants';
import TicketTable from './ticket-table';

const ScrollTable = ({ tableData, field }) => (
	<Col span={24} className="title scroll_table_container">
		<div className="scroll_table">
			<table className="bordered-bottom dashboard-table" id="payments">
				<tbody>
					{tableData?.map((data) => (
						<tr key={data?._id}>
							<td>
								<span>{data[field]}</span>
							</td>
							<td style={{ textAlign: 'right' }}>
								<span>{data?.count}</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
		<div className="scroll_table_footer">
			<div>
				<span>Total</span>
			</div>
			<div style={{ textAlign: 'right' }}>
				<span>{(tableData || []).reduce((a, b) => a + b.count, 0)}</span>
			</div>
		</div>
	</Col>
);

const DashboardPresentational = ({ dashboardData, tableData, loginRedux, specificDate, loading, setSpecificDate, getDashboardData }) => {
	// const [open, setOpen] = useState(false);
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	//   const ref3 = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			// setOpen(true);
		}, 500);
	}, []);

	// const steps = [
	// 	{
	// 		title: 'Upload File',
	// 		description: 'Put your files here.',
	// 		cover: <img alt="tour.png" src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png" />,
	// 		target: () => ref1.current,
	// 	},
	// 	{
	// 		title: 'Save',
	// 		description: 'Save your changes.',
	// 		target: () => ref2.current,
	// 	},
	// ];
	return (
		<Row className="dashboard_container">
			{loading && <div className="dashboard_container_loader"><Spin /></div>}
			<Col xl={24}>
				<Row gutter={[10, 10]}>
					<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
						<Row>
							<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h4>
									Hi there, {loginRedux?.firstName} {loginRedux?.lastName}! 👋
								</h4>
								<div>
									<DatePicker
										style={{ width: 150 }}
										format={DATE_FORMAT.DD_MM_YYYY}
										value={specificDate}
										onChange={(date, dateString) => setSpecificDate(date)}
									/>
									<Button
										type="primary"
										icon={<ReloadOutlined />}
										loading={loading}
										onClick={getDashboardData}
										style={{ marginLeft: 10 }}
									/>
								</div>
							</Col>
						</Row>
					</Col>

					<Col span={24} ref={ref1}>
						<Row gutter={[10, 10]}>
							<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
								<Row>
									<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div
											style={{
												fontWeight: 'bold',
												fontSize: '1rem',
											}}>
											Total Employees
										</div>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.totalEmployees} />
														</div>
														<div className="name">{'Total Employees'}</div>
													</Col>
													<Col span={5} className="icon">
														<BsFillPersonFill />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
							{/* <Col className="title" span={24} style={{ fontWeight: 'normal' }}>
								<Row>
									<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div
											style={{
												fontWeight: 'bold',
												fontSize: '1rem',
											}}>
											Designation Employees
										</div>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									{dashboardData?.designation_Employees?.map((data, index) => (
										<Col span={4}>
											<Row className="dashboard_card">
												<Col span={24}>
													<Row align="top">
														<Col span={19}>
															<div className="value">
																<AnimatedNumber value={data?.count} />
															</div>
															<div className="name">{data?.designationName}</div>
														</Col>
														<Col span={5} className="icon">
															<BsFillPersonFill />
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									))}
								</Row>
							</Col>
							<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
								<Row>
									<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div
											style={{
												fontWeight: 'bold',
												fontSize: '1rem',
											}}>
											Shift Employees
										</div>
									</Col>
								</Row>
							</Col> */}
							{/* <Col span={24}>
								<Row gutter={[10, 10]}>
									{dashboardData?.shift_Employees?.map((data, index) => (
										<Col span={4}>
											<Row className="dashboard_card">
												<Col span={24}>
													<Row align="top">
														<Col span={19}>
															<div className="value">
																<AnimatedNumber value={data?.count} />
															</div>
															<div className="name">{data?.shiftName}</div>
														</Col>
														<Col span={5} className="icon">
															<BsFillPersonFill />
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									))}
								</Row>
							</Col> */}
						</Row>
					</Col>

					{(tableData || [])?.length > 0 ? (
						<Col xl={24} style={{ paddingTop: 20 }} ref={ref2}>
							<Row gutter={[10, 10]}>
								<Col xl={24} style={{ paddingTop: 20 }}>
									<Row gutter={[10, 10]}>
										<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
											<div>Tickets</div>
										</Col>
									</Row>
								</Col>
								<Col className="title" span={14}>
									<TicketTable tableData={tableData} />
								</Col>
							</Row>
						</Col>
					) : null}

					<Col xl={24}>
						<Row gutter={[10, 10]}>
							<Col span={8} style={{ paddingTop: 20 }}>
								<Row gutter={[10, 10]}>
									<Col
										style={{
											fontWeight: 'bold',
											fontSize: '1rem',
										}}
										span={24}>
										Department Employees
									</Col>
									<ScrollTable tableData={dashboardData?.department_Employees} field="departmentName" />
								</Row>
							</Col>

							<Col span={8} style={{ paddingTop: 20 }}>
								<Row gutter={[10, 10]}>
									<Col
										style={{
											fontWeight: 'bold',
											fontSize: '1rem',
										}}
										span={24}>
										Designation Employees
									</Col>
									<ScrollTable tableData={dashboardData?.designation_Employees} field="designationName" />
								</Row>
							</Col>

							<Col span={8} style={{ paddingTop: 20 }}>
								<Row gutter={[10, 10]}>
									<Col
										style={{
											fontWeight: 'bold',
											fontSize: '1rem',
										}}
										span={24}>
										Shift Employees
									</Col>
									<ScrollTable tableData={dashboardData?.shift_Employees} field="shiftName" />
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* <Tour open={open} onClose={() => setOpen(false)} steps={steps} /> */}
			</Col>
		</Row>
	);
};

export default DashboardPresentational;
