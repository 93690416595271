import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import { getDateFormat } from 'services/Utils';
import AttendancesListPresentational from './attendances-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const AttendancesListFunctional = React.memo(() => {
	const attendanceRedux = useSelector((state) => state.attendanceRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [attendanceAddModal, setAttendanceAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(attendanceRedux.attendances);
	const [editAttendance, setEditAttendance] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getAttendances = useCallback(() => {
		let url = `${SERVER_IP}attendance?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_ATTENDANCES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getAttendances();
	}, [getAttendances]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_ATTENDANCE === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_ATTENDANCE'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getAttendances();
		}
	}, [globalRedux.apiStatus, dispatch, getAttendances]);

	useEffect(() => {
		setTableData(attendanceRedux.attendances);
	}, [attendanceRedux.attendances]);
	
	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((attendance) => {
			return (
				(attendance?.employeeId?.employeeName || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(getDateFormat(attendance?.checkIn)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(getDateFormat(attendance?.checkOut)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(attendance?.inputType || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(attendance?.workingHours || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(attendance?.overtime || '')?.toString().toLowerCase().includes(searchKey.toLowerCase()) ||
				(attendance?.remarks || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditAttendance(rowData);
		setAttendanceAddModal(true);
	};

	const handleAddAttendance = () => {
		setEditAttendance(null);
		setAttendanceAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'attendanceNumber',
			key: 'attendanceNumber',
			sorter: (a, b) => a?.attendanceNumber - b?.attendanceNumber,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Employee Name',
			dataIndex: 'employeeId',
			key: 'employeeId',
			sorter: (a, b) => a?.employeeId?.employeeName?.localeCompare(b?.employeeId?.employeeName),
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.employeeName} />
				</div>
			),
		},
		{
			title: 'Check In',
			dataIndex: 'checkIn',
			key: 'checkIn',
			sorter: (a, b) => new Date(a.checkIn) - new Date(b.checkIn),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={getDateFormat(value)}
				/>
			),
		},
		{
			title: 'Check Out',
			dataIndex: 'checkOut',
			key: 'checkOut',
			sorter: (a, b) => new Date(a.checkOut) - new Date(b.checkOut),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={getDateFormat(value)}
				/>
			),
		},
		{
			title: 'Working Hours',
			dataIndex: 'workingHours',
			sorter: (a, b) => a?.workingHours?.localeCompare(b?.workingHours),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Overtime',
			dataIndex: 'overtime',
			sorter: (a, b) => a?.overtime?.localeCompare(b?.overtime),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			sorter: (a, b) => a?.remarks?.localeCompare(b?.remarks),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'displayName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_ATTENDANCES === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setAttendanceAddModal(false);
		setEditAttendance(null);
	}, [setAttendanceAddModal, setEditAttendance]);

	return (
		<AttendancesListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddAttendance,
				currentPage,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				attendanceAddModal,
				setAttendanceAddModal,
				refreshList: getAttendances,
				editAttendance,
				handleClose,
			}}
		/>
	);
});

export default AttendancesListFunctional;
