import React, { useEffect } from 'react';
import { Row, Input, Button, Form, Drawer } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const AddDesignation = ({ designationAddModal, width = '40%', editDesignation, setDesignationAddModal, refreshList, handleClose }) => {
	const dispatch = useDispatch();
	const { selectedOrganization, apiStatus } = useSelector((state) => state.globalRedux);
	const form = Form.useForm()[0];

	useEffect(() => {
		if (editDesignation) {
			form.setFieldsValue({
				designationName: editDesignation?.designationName,
				remarks: editDesignation?.remarks,
			});
		} else {
			form?.resetFields();
		}
	}, [editDesignation, form]);

	const handleSubmit = (values) => {
		const data = {
			orgId: selectedOrganization?._id,
			...values,
		};
		const apiAction = !editDesignation ? 'ADD_DESIGNATION' : 'EDIT_DESIGNATION';
		const url = !editDesignation ? '' : `${SERVER_IP}designation/${editDesignation._id}?orgId=${selectedOrganization?._id}`;
		dispatch(!editDesignation ? postApi(data, apiAction) : putApi(data, apiAction, url));
	};

	useEffect(() => {
		if (apiStatus.ADD_DESIGNATION === 'SUCCESS' || apiStatus.EDIT_DESIGNATION === 'SUCCESS') {
			dispatch(resetApiStatus(editDesignation ? 'EDIT_DESIGNATION' : 'ADD_DESIGNATION'));
			refreshList?.();
			handleClose?.();
			form?.resetFields();
		}
	}, [apiStatus, editDesignation, setDesignationAddModal, refreshList, handleClose, dispatch, form]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = apiStatus.ADD_DESIGNATION === API_STATUS.PENDING || apiStatus.EDIT_DESIGNATION === API_STATUS.PENDING;

	return (
		<Drawer
			placement="right"
			title={`${editDesignation ? 'Edit' : 'New'} Designation`}
			width={width || '40%'}
			open={designationAddModal}
			closable
			onClose={() => setDesignationAddModal(false)}
			destroyOnClose={true}>
			<Row style={{ marginTop: 0 }}>
				<Form
					name="add-designation"
					className="required_in_right"
					style={{ width: '100%' }}
					labelAlign="left"
					form={form}
					onFinish={handleSubmit}
					initialValues={{}}
					{...layer1FormCol}>
					<Form.Item
						label="Designation Name"
						name="designationName"
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input placeholder="Designation Name" />
					</Form.Item>
					<Form.Item label="Remarks" name="remarks">
						<Input.TextArea placeholder="Remarks" />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setDesignationAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editDesignation ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Drawer>
	);
};

export default AddDesignation;
