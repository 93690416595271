import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { generatePagination } from 'helpers';
import { API_STATUS } from 'constants/app-constants';
import DesignationsListPresentational from './designations-list-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const DesignationsListFunctional = React.memo(() => {
	const designationRedux = useSelector((state) => state.designationRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [designationAddModal, setDesignationAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(designationRedux.designations);
	const [editDesignation, setEditDesignation] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const dispatch = useDispatch();

	const getDesignations = useCallback(() => {
		let url = `${SERVER_IP}designation?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_DESIGNATIONS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getDesignations();
	}, [getDesignations]);

	useEffect(() => {
		generatePagination(tableData);
	}, [tableData]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_DESIGNATION === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_DESIGNATION'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getDesignations();
		}
	}, [globalRedux.apiStatus, dispatch, getDesignations]);

	useEffect(() => {
		setTableData(designationRedux.designations);
	}, [designationRedux.designations]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((designation) => {
			return (
				(designation?.designationName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(designation?.remarks || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditDesignation(rowData);
		setDesignationAddModal(true);
	};

	const handleAddDesignation = () => {
		setEditDesignation(null);
		setDesignationAddModal(true);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'designationNumber',
			key: 'designationNumber',
			sorter: (a, b) => a?.designationNumber - b?.designationNumber,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Designation Name',
			dataIndex: 'designationName',
			key: 'designationName',
			sorter: (a, b) => a?.designationName?.localeCompare(b?.designationName),
			fixed: 'left',
			render: (value) => (
				<div style={{ fontWeight: 'bold' }}>
					<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
				</div>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			sorter: (a, b) => a?.remarks?.localeCompare(b?.remarks),
			align: 'left',
			render: (value) => <HighlightComponent searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'displayName',
			render: (value, row, index) => {
				return (
					<Row justify="center">
						<Col className="edit_icon" onClick={() => handleDrawer(row)}>
							<EditOutlined />
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_DESIGNATIONS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		// fixed: true,
	};

	const handleClose = useCallback(() => {
		setDesignationAddModal(false);
		setEditDesignation(null);
	}, [setDesignationAddModal, setEditDesignation]);

	return (
		<DesignationsListPresentational
			{...{
				filteredData,
				column,
				tableLoading,
				rowSelection,
				selectedRowKeys,
				handleAddDesignation,
				currentPage,
				pageSize,
				intialPageSizeOptions,
				initialPageSize,
				handleTableChange,
				setSearchKey,
				getStartingValue,
				getEndingValue,
				designationAddModal,
				setDesignationAddModal,
				refreshList: getDesignations,
				editDesignation,
				handleClose,
			}}
		/>
	);
});

export default DesignationsListFunctional;
